@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('http://fonts.googleapis.com/css?family=Roboto:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.App {
  text-align: center;
}

.socialBar {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 50px;
  left: 0;
  background-color: rgba(0, 0, 0, 0); /* Set background color to fully transparent */
  padding: 20px;
}

.vline {
  border-left: 2px solid white;
  height: 30vh;
  margin-left: 22px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.spacing {
  height: 1vh;
}

.navBar {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 50px;
  right: 0;
  background-color: rgba(0, 0, 0, 0); /* Set background color to fully transparent */
  padding: 20px;
  z-index: 1000;
}

.vlineMenu {
  border-left: 2px solid white;
  height: 30vh;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 50%;
  margin-right: 50%;
}

.pendingLink {
  text-decoration: inherit;
  font-family: 'Rubik', sans-serif;
  color: #FFFFFF;
  font-size: 30px;
  font-weight: 300;
  text-align: center;
}

.activeLink {
  text-decoration: inherit;
  font-family: 'Rubik', sans-serif;
  color: #B6EADA;
  font-size: 40px;
  font-weight: 500;
  text-align: center;
}
