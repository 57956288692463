/* About.css */
.aboutBody {
    background-color: #2C3333;
    color: white;
    padding: 20px;
  }
  
  .about {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .aboutTitle h2 {
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  .contactInfo p,
  .summary p,
  .job p,
  .education p,
  .projects p,
  .certifications p,
  .technicalSkills p,
  .interests p {
    margin: 5px 0;
  }
  
  h3 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  /* Add these styles for the h3 headers */
  .section {
    overflow: hidden; /* Clear the float */
    margin-bottom: 20px; /* Add spacing between sections */
  }
  
  .sectionHeader {
    float: left; /* Float the sectionHeader to the left */
    width: 150px; /* Adjust the width as needed */
    font-weight: bold; /* Make the sectionHeader bold */
    margin-top: 15px;
    color: #61dafb;
  }
  
  .sectionContent {
    margin-left: 160px; /* Add some spacing between sectionHeader and sectionContent */
  }
  
  a {
    color: white;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  a:hover {
    color: #61dafb;
  }
  
  .columns {
    display: flex;
    margin-top: -15px;
  }
  
  .column {
    flex: 1;
    padding: 10px;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  ul li {
    font-size: 16px;
    margin-bottom: 5px;
  }
  
  .buttonSection {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
  }

  .resumeButton {
    font-family: sans-serif; /* Change the font to sans-serif */
    font-size: 20px; /* Adjust the font size as needed to make it bigger */
    background-color: #61dafb; /* Set the background color to #61dafb */
    border-color: #61dafb; /* Set the border color to #61dafb */
    color: white; /* Set the text color to white */
    /* You can add more styles like padding, margin, etc. here if needed */
  }