@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('http://fonts.googleapis.com/css?family=Roboto:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.homeBody {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
}

.intro {
    margin-top: 0%;
    display: flex;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    outline-style:none;
}
.introLead{
    font-family: 'Roboto', 'Times New Roman';
    color: #B6EADA;
    font-size: 20px;
    font-weight: 100;
    margin-left: -75%;
}

.introName{
    font-family: 'Rubik', sans-serif;
    color: #FFFFFF;
    font-size: 100px;
    font-weight: 500;
}

.introInfo{
    font-family: 'Rubik', sans-serif;
    color: #A5C9CA;
    font-size: 40px;
    font-weight: 500;
}