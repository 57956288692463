@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('http://fonts.googleapis.com/css?family=Roboto:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
    background-color: #2C3333;
}

.project {
    height: 300vh;
    display: flex;
}

.projectTitle {
    width: 50%;
    height: 100vh;
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    right: 5%;
    color: #61dafb;
}

.projectPages {
    width: 50%;
}

.projectPage {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    color: white;
}

.projectHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.projectName {
    font-weight: bold;
    font-size: xx-large;
    color: white;
}

.techStack {
    color: #06d6a0;
    font-weight: bold;
}

.projectButtons {
    margin-top: 5%;
}

.projectDetail {
    width: 80%;
    font-size: 18px;
    line-height: 30px;
    margin-left: 5%;
}

.projectPage.one {
    background-color: #495057;
}

.projectPage.two {
    background-color: #6c757d;
}

.projectPage.three {
    background-color: #495057;
}

.projectPage.four {
    background-color: #6c757d;
}